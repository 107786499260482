
import { Vue, Component, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { saveFcmToken } from '@/api/fcm-token';
import { FcmTokenModule } from '@/store/fcm-token';

@Component({
  name: 'Login',
})
export default class extends Vue {
  @Watch('loginForm', { deep: true })
  private handleChangeLoginForm() {
    this.errorMessage = '';
    UserModule.ResetErrorMessage();
  }

  mounted() {
    if (UserModule.token) {
      if (this.routerName === 'AppLogin') this.$router.push({ name: 'AppMain' });
      else this.$router.push({ name: 'Index' });
    }
  }

  get routerName() {
    return this.$route.name;
  }

  private loginForm = {
    username: '',
    password: '',
  };

  private fcmToken = '';

  private errorMessage = '';

  private handleLogin() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        UserModule.Login(this.loginForm).then(() => {
          if (this.routerName === 'AppLogin') {
            const data = {
              token: FcmTokenModule.fcmToken,
            };
            saveFcmToken(data).then(() => {
              this.$router.push({ name: 'AppMain' });
            }).catch(() => {
              UserModule.LogOut();
            });
          } else {
            this.$router.push({ name: 'Index' });
          }
        }).catch((error) => {
          // nothing
          if (this.routerName === 'AppLogin') this.errorMessage = UserModule.errorMessage || '로그인에 실패했습니다.';
        });
      } else {
        const firstItem = this.$validator.errors.items[0];
        (this.$refs[firstItem.field] as HTMLInputElement).focus();
        if (this.routerName === 'AppLogin') this.errorMessage = firstItem.msg;
        else window.alert(firstItem.msg);
      }
    });
  }
}
